$rtl-support: true;

@import '../_appwork/include';
@import '../_appwork/reboot';
@import '../_appwork/type';
@import '../_appwork/utilities';
@import '../_appwork/base';
@import '../_appwork/tables';
@import '../_appwork/buttons';
@import '../_appwork/button-group';
@import '../_appwork/badge';
@import '../_appwork/dropdown';
@import '../_appwork/nav';
@import '../_appwork/pagination';
@import '../_appwork/alert';
@import '../_appwork/tooltip';
@import '../_appwork/popover';
@import '../_appwork/forms';
@import '../_appwork/custom-forms';
@import '../_appwork/switcher';
@import '../_appwork/input-group';
@import '../_appwork/modal';
@import '../_appwork/breadcrumb';
@import '../_appwork/list-group';
@import '../_appwork/navbar';
@import '../_appwork/card';
@import '../_appwork/accordion';
@import '../_appwork/carousel';
@import '../_appwork/close';
@import '../_appwork/footer';
@import '../_appwork/sidenav';
@import '../_appwork/layout';
@import '../_appwork/app-brand';
